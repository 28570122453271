// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #575757; /* davys_grey */
$secondary         : #575757; /* grey */



// /* MAIN PALETTE */ //
$charcoal						: #2E4057; /* charcoal */
$rich_electric_blue	: #0193CF; /* rich_electric_blue */

// /* SEC PALETTE */ //
$sapphire_blue			: #0066A4; /* sapphire_blue */
$ucla_blue					: #577192; /* ucla_blue */
$prussian_blue      : #223041;
$vivid_blue         : #49C9FE;
$lapiz_lazuli       : #00588F;
$cooler_blue        : #005C94;
$cultured           : #F6F7F8;

$gainsboro					: #DADEE0; /* gainsboro */
$lavender_mist			: #E5E6EF; /* lavender_mist */		/* borders */
$platinum						: #E0E5EB; /* platinum */					/* sub menu */
$nickel							: #707070; /* nickel */
$cg_blue						: #1580A5; /* cg_blue */
$light_gray					: #CAD1DB; /* light_grey */

$anti_flash_white		: #F1F3F5; /* anti_flash_white */
$isabelline					: #EDEEF0; /* isabelline */
$onyx								: #383838; /* onyx */
$onyx_alt						: #353738; /* onyx */
$davys_grey					: #575757; /* davys_grey */

$onyx_70						: rgba(53,55,56,0.7);
$cg_blue_70					: rgba(21,128,165,0.7);

$black             : #000;
$grey              : #666;
$white             : #FFF;

// /* PATTERNS */
$bg_pattern_dark		: $prussian_blue $pattern_white scroll 50% 50%;
$bg_pattern_light		: $rich_electric_blue $pattern_white  scroll 50% 50%;
$bg_pattern_white		: white $pattern_dark scroll 50% 50%;
$bg_pattern_grey		: $onyx $pattern_white scroll 50% 50%;

$bg_pattern_breadcrumb		: $prussian_blue $pattern_white scroll 50% 28%;


// /* ATTACHMENTS */
$dark_spring_green			: #207347; /* dark_spring_green */ 			/* XLS */
$international_orange		: #C11E07; /* international_orange */ 	/* PDF */
$bdazzled_blue					: #2D5994; /* bdazzled_blue */					/* DOC */
$dark_pastel_red				: #D04727; /* dark_pastel_red */        /* PPT */
$lemon_curry						: #D19B15; /* lemon_curry */ 						/* JPG */
$dark_imperial_blue			: #023A6D; /* dark_imperial_blue */     /* TXT */


$xls_colour : $dark_spring_green;
$pdf_colour : $international_orange;
$doc_colour : $bdazzled_blue;
$ppt_colour : $dark_pastel_red;
$jpg_colour : $lemon_curry;
$txt_colour : $dark_imperial_blue;
$zip_colour : $black;


// /* LINKS */ //
$action-default      : $lapiz_lazuli;
$action-hover        : $prussian_blue;
$action-active       : $prussian_blue;
$action-active-hover : $prussian_blue;


// /* SHADOWS */ //
$box-shadow : 0px 2px 14px 0 rgba(0,0,0,0.12); //0 2px 14px 0 rgba(0,0,0,0.12);

// /* BORDERS */ //
$primary-border-color          : $ucla_blue;
$primary-border-radius         : 0;
$primary-border                : 2px solid $primary-border-color;

$secondary-border-color        : $lavender_mist;
$secondary-border-color-radius : 0;
$secondary-border              : 2px solid $secondary-border-color;

$tertiary-border-color         : $rich_electric_blue;
$tertiary-border-radius        : 0;
$tertiary-border               : 2px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $platinum;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $platinum;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $platinum;
$form-box-shadow       : none; //$box-shadow;
$form-box-shadow-focus : none; //$box-shadow;
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid $secondary-border-color;
$form-border-radius    : 0;

$button-bg             : $action-default;
$button-hover-bg       : $action-hover;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : $isabelline;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $platinum !default;
