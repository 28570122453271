.item-list  ul.pager {
	border-top: $primary-border;
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $secondary !important;
		font-weight: 600;
		//padding:0.25em 0.5em;
		padding: .1em .5em;
    display: inline-block;
		
		&:hover {
			background:$action-hover;
			color:#FFFFFF !important;
		}
	}
	li.pager-item.pager-next {
		float: right;
		position: absolute;
		right: 55px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			//background: $arrow-right;
			padding-right: 20px;
			background-position: 100% 50%;
			background-size: 8px;
		}
	}
	li.pager-item.pager-last {
		float: right;
		a {
			display: block;
			//background: $arrow-right-last;
			padding-right: 20px;
			background-position: 100% 50%;
			background-size: 14px;
		}
	}
	li.pager-item.pager-previous {
		float: left;
		position: absolute;
		left: 55px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			//background: $arrow-left;
			padding-left: 20px;
			background-position: 0% 50%;
			background-size: 8px;
		}
	}
	li.pager-item.pager-first {
		float: left;
		a {
			display: block;
			//background: $arrow-left-first;
			padding-left: 20px;
			background-position: 0% 50%;
			background-size: 14px;
		}
	}
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		background: $action-default;
		color:white;
		padding:0.25em 0.5em;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
		display: inline-block;
		margin: 5px;
		padding: 0.1em .5em;
	}
}

.postscript-first .section-row.palette-highlight .item-list ul.pager {
	a {
		color:$palette-highlight-link-color !important;

		&:hover {
			color:white !important;
		}
	}
}