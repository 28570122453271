fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background-color: white;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;


}


// CUSTOM
fieldset {
	padding: 10px;
	
	legend {
		margin-bottom: -20px;
		
		.fieldset-legend {
			font-size: 28px;
			padding: 0 10px;
		}
	}
	
	.fieldset-wrapper {
		padding: 10px;
		
		input {
			max-width: 720px;
		}
		
		.webform-datepicker {
			.form-item,
			input {
				display: inline-block;
				float: left;
				margin-right: 10px;
				margin-top: 0.25rem;
			}
		}
		
		.form-item.webform-component-date {
			overflow: hidden;
		}
	}
	
	.webform-component-time .webform-container-inline {
		margin-left: -5px;
		
		div {
			display: inline-block;
			margin: 0 5px;
		}
	}
}

.ui-datepicker {
	width: 13.8em;
	
	.ui-datepicker-title {
		font-size: 15px;
	}
}


