// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;
	box-shadow:$card-shadow;

	
	&:after {
		content: " ";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;

	}

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
		
		position: relative;
		overflow: visible;
	}
}

.card-display-date,
.card-date,
.card-date-display-override {
	background: $card-date-bg-color;
	border:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:6px 12px;
	width: max-content;
	text-transform: uppercase;
	margin-bottom: 10px;
	display: inline-block;
}

.palette-alt-3 {
	.card-display-date,
	.card-date,
	.card-date-display-override {
		background: $card-alt3-date-bg-color;
		border:$card-alt3-date-border;
		border-radius:$card-alt3-date-border-radius;
		color:$card-alt3-date-text-color;
		font:$card-alt3-date-font;
	}
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	/*overflow: hidden;*/
	a {
		display: flex;
    padding: 5px 35px 4px 5px;
    position: relative;
    margin: 0 0 10px;
		&:after {
			content: "";
			//display: inline-block;
			width: 28px;
			height: 28px;
			//border-radius: 50%;
			//margin-left: 10px;
			//vertical-align: sub;
			
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase; 
	}
}

.card-citation {
	font-style:italic;
}
/*.card-date,*/
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
/*@include card-hidden-summary;*/

.card-image,
.card-image-et,
.card-image-outer,
.card-cover-image,
.card-full-width-image,
.card-picture,
.card-image-URL {
	&+.card-text{
   /*margin-top: -35px;*/
	}
}

// IMAGE PADDING
.section-row:not(.section-with-slider) {
	.card-image, .card-video, .card-photo, .card-logo {
		padding: 0 10px;
		position: relative;
		/*background-color: white;*/

		picture {
			&:before,
			&:after {
				display: block;
				content: " ";
				height: 10px;
				width: 10px;
				position: absolute;
				bottom: 0;
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
	}
}
.section-row.palette-alt-3 .view.view-display-id-block {
	.card-image {
		padding: 0;
		picture {
			&:before,
			&:after {
				display: none;
			}
		}
	}
}

// TITTLE POSITION - ONLY ON BOX LINKS
.section-row:not(.palette-alt-3),
.side-row:not(.palette-alt-3){
	.view-links {	
		.card {
			
			.card-text { 
				position: relative;
				overflow: visible;
				
				display: inline-flex;
				flex-direction: column; 
				width: 100%;
		
				h3 {
					order: -1;
					padding: 15px 30px;					
					margin: -40px 0 20px -20px;
					display: inline-table;
				}
			}
			
			.card-image, .card-video, .card-photo, .card-logo {
				&+.card-text {

					h3 {
						margin: -34px 0 20px -20px;
					}
					
					.card-display-date, .card-date, .card-date-display-override { 
						margin-top: 0;
						margin-bottom: 10px;
						background: none;
						border: none;
						color: $secondary;
						padding: 0;
						position: relative;
					}
				}
			}
			
			&:hover {
			.card-image, .card-video, .card-photo, .card-logo {
				&+.card-text {
					.card-display-date, .card-date, .card-date-display-override { 
						background: none;
						border: none;
						color: $secondary;
						padding: 0;
					}
				}
			}
				
			}
		}
	}
}

// DATE POSITION - EXCLUDE BOX LINKS
.view:not(.view-links){
	.card {

		.card-image, .card-video, .card-photo, .card-logo {
			&+.card-text {
				.card-display-date, .card-date, .card-date-display-override { 
					margin-top: -60px;
					margin-bottom: 30px;
					position: absolute;
				}
			}	
		}
	}
}

.row-max-6, .row-max-4, .row-max-3 {
	.card-text {
		width: 100%;
	}
} 

.view-links article:not(.node-with-picture) {
		//padding-top: 14px;
	&:before {
		content: " ";
		display: block;
		height: 14px;
	}
	.card {
		overflow: visible;
	}
}
	
// BOX LINK ON ROW1 AND ROW 2
//.section-row:not(.view-type-slider).row-max-1
.section-row:not(.section-with-slider).row-max-1,
.section-row:not(.section-with-slider).row-max-2 {
	@include media($normal) {
		.view-links {	
			.card {
				overflow: visible !important;
				/*background-color: transparent;*/
				
				.card-image+.card-text {
					width: 50%;
					float: left;
					margin-bottom: 20px;
					background-color: white;
					
					h3 {
						margin-left: -40px;
						width: max-content;
					}
				}
				
				.card-image {
					width: 50%;
					float: left;
					margin-bottom: 20px;
					padding: 0;
					
					picture:after,
					picture:before {						
						display: none; 
					}
				}
			}
		}	
	}
}

//////////////////// lo base styles ///////////////////////
/*.section-row:not(.view-type-slider).row-max-2 .views-row:nth-child(2n+1) {
    margin-left: 0;
}*/
