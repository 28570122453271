// /* CARD GENERAL */
@mixin card-hover() {
	/*background-color:$card-hover-bg-color;*/
	border:$card-hover-border;
	box-shadow:$card-hover-shadow;

/*	* {
		color:$card-text-hover-color;		
	}*/

	.card-text,
	.card-text * {
		//color:$card-text-hover-color;
		
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-action-hover;
			transition: background  300ms linear 0s;
		}
		
		.card-display-date *,
		.card-date *,
		.card-date-display-override * {
			//color: $card-date-hover-text-color;
		}
	}

}

@mixin card-alt-3-hover() {
	background-color:$card-alt3-hover-bg-color;
	border:$card-alt3-hover-border;
	box-shadow:$card-alt3-hover-shadow;

/*	* {
		color:$card-text-hover-color;		
	}*/

	.card-text,
	.card-text * {
		color:$card-alt3-text-hover-color;
		
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-alt3-action-hover;
		}

		.card-display-date * {
			background:$card-alt3-date-hover-bg-color;
			color: $card-alt3-date-hover-text-color;
		}
	}
			
	.card-image, .card-video, .card-photo, .card-logo {
		picture:after, picture:before {
			background-color:$card-alt3-hover-bg-color;
			transition: $background-transition;
		}
	}	
}

// /* CARD HOVER BASE COLOUR OPTIONS */
@mixin card-light-title-hover() {
	.card-text,
	.card-text * {
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color:$card-light-card-title-color-hover;
			background:$card-light-card-title-bg-hover;
		}
	}
}

@mixin card-medium-title-hover() {
	.card-text,
	.card-text * {
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color:$card-medium-card-title-color-hover;
			background:$card-medium-card-title-bg-hover;
		}
	}
}

@mixin card-dark-title-hover() {
	background:$card-alt3-hover-bg-color;
		
	.card-image, .card-video, .card-photo, .card-logo {
		picture:before, picture:after {
			background-color:$card-alt3-hover-bg-color;
			transition: $background-transition;
		}
	}	
	
	.card-text,
	.card-text * {
		color:$card-alt3-text-hover-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			//color:$card-dark-card-title-color-hover;
			//background:$card-dark-card-title-bg-hover;
			color:white;
		}
	}
}

@mixin card-highlight-title-hover() {
	.card-text,
	.card-text * {
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color:$card-highlight-card-title-color-hover;
			background:$card-highlight-card-title-bg-hover;
		}
	}
}

@mixin card-alt-title-hover() {
	background:$card-alt-card-title-bg;
	
	.card-text/*,
	.card-text **/ {
		h1, h2, h3, h4, h5, h6 {
		.card-title,
		.card-title-et,
		.card-title-field {
			color:$card-alt-card-title-color-hover;
			background:$card-alt-card-title-bg-hover;
			transition: $background-transition;
			}
		}
	}
}


// /* CARD BASE COLOUR OPTIONS */


@mixin card-light() {
	background-color: $card-light-bg-color;
	border-color: $card-light-border-color;
		
	.card-image, .card-video, .card-photo, .card-logo {
		picture:before, picture:after {
			background-color:$card-light-bg-color;
			transition: $background-transition;
		}
	}	

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-light-title-color;
		//color:$card-light-card-title-color;
		//background:$card-light-card-title-bg;
	}
	.card-text {
		color:$card-light-text-color;

		a {
			color:$card-light-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-light-title-color;
			}
			&:hover {
				color:$card-light-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-light-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-light-bg-color;
		}
	}

	.card-subtitle {
		color: $card-light-subtitle-color;
	}
}

@mixin card-light-title() {
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color:$card-light-card-title-color;
		background:$card-light-card-title-bg;
		width: fit-content;
	}
}

@mixin card-medium() {
	background-color: $card-medium-bg-color;
	border-color: $card-medium-border-color;
		
	.card-image, .card-video, .card-photo, .card-logo {
		picture:before, picture:after {
			background-color:$card-medium-bg-color;
		}
	}	

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-medium-title-color;
		//color:$card-medium-card-title-color;
		//background:$card-medium-card-title-bg;
	}
	.card-text {
		color:$card-medium-text-color;

		a {
			color:$card-medium-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-medium-title-color;
			}
			&:hover {
				color:$card-medium-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-medium-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-medium-bg-color;
		}
	}

	.card-subtitle {
		color: $card-medium-subtitle-color;
	}
}

@mixin card-medium-title() {
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color:$card-medium-card-title-color;
		background:$card-medium-card-title-bg;
		width: fit-content;
	}
}

@mixin card-dark() {
	background-color: $card-dark-bg-color;
	border-color: $card-dark-border-color;
		
	.card-image, .card-video, .card-photo, .card-logo {
		picture:before, picture:after {
			background-color:$card-dark-bg-color;
		}
	}	

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-dark-title-color;
		//color:$card-dark-card-title-color;
		//background:$card-dark-card-title-bg;
	}
	.card-text {
		color:$card-dark-text-color;

		a {
			color:$card-dark-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-dark-title-color;
			}
			&:hover {
				color:$card-dark-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-dark-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-dark-bg-color;
		}
	}

	.card-subtitle {
		color: $card-dark-subtitle-color;
	}
}

@mixin card-dark-title() {
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color:$card-dark-card-title-color;
		background:$card-dark-card-title-bg;
		width: fit-content;
	}
}

@mixin card-highlight() {
	background-color: $card-highlight-bg-color;
	border-color: $card-highlight-border-color;
		
	.card-image, .card-video, .card-photo, .card-logo {
		picture:before, picture:after {
			background-color:$card-highlight-bg-color;
		}
	}	

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-highlight-title-color;
		//color:$card-dark-highlight-title-color;
		//background:$card-highlight-card-title-bg;
	}
	.card-text {
		color:$card-highlight-text-color;

		a {
			color:$card-highlight-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-highlight-title-color;
			}
			&:hover {
				color:$card-highlight-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-highlight-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-highlight-bg-color;
		}
	}

	.card-subtitle {
		color: $card-highlight-subtitle-color;
	}
}

@mixin card-highlight-title() {
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color:$card-highlight-card-title-color;
		background:$card-highlight-card-title-bg;
		width: fit-content;
	}
}

@mixin card-alt-title() {
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color:$card-alt-card-title-color;
		background:$card-alt-card-title-bg;
		width: fit-content;
	}
	
	h1, h2, h3, h4, h5, h6 {
		width: 100%;
		text-align: center;
		
		.card-title,
		.card-title-et,
		.card-title-field {
			border: 2px solid white;
			width: fit-content;
			padding: 10px;
			display: inline-block;
		}
	}
	h3 {
		font-size: 1.375em;
		text-transform: uppercase;
	}
}



// /* CARD MORE LINKS */
@mixin card-more-link-default() {
	a.card {
		background:$card-more-link-default-bg;
		color: $card-more-link-default-color;
		&:after {
			background: $card-more-link-default-icon;
		}
		&:hover {
			background:$card-more-link-default-hover-bg;
			color: $card-more-link-default-hover-color;
			&:after {
				background: $card-more-link-default-hover-icon;
			}
		}
	}
}

@mixin card-more-link-alt() {
	a.card {
		background:$card-more-link-alt-bg;
		color: $card-more-link-alt-color;
		&:after {
			background: $card-more-link-alt-icon;
		}
		&:hover {
			background:$card-more-link-alt-hover-bg;
			color: $card-more-link-alt-hover-color;
			&:after {
				background: $card-more-link-alt-hover-icon;
			}
		}
	}
}


// /* CARD FEED MORE LINKS */
@mixin card-feed-link-default() {
	a {
		background:$card-feed-link-default-bg;
		color: $card-feed-link-default-color;
		&:after {
			background: $card-feed-link-default-icon;
		}
		&:hover {
			background:$card-feed-link-default-hover-bg;
			color: $card-feed-link-default-hover-color;
			box-shadow: $button-link-default-shadow;
			&:after {
				background: $card-feed-link-default-hover-icon;
			}
		}
	}
}


@mixin card-feed-link-alt() {
	a {
		background:$card-feed-link-alt-bg;
		color: $card-feed-link-alt-color;
		&:after {
			background: $card-feed-link-alt-icon;
		}
		&:hover {
			background:$card-feed-link-alt-hover-bg;
			color: $card-feed-link-alt-hover-color;
			box-shadow: $button-link-alt-shadow;
			&:after {
				background: $card-feed-link-alt-hover-icon;
			}
		}
	}
}