// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		@media (min-width:880px) and (max-width:1150px) {
			min-height:34px;
		}

		@include media(1150px) {
			min-height:40px;
		}
	}
}

// MENU STYLES
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;
		.main-menu-btn {
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin:0;

			@media (min-width:$narrow) and (max-width:879px) {
				margin: -80px 20px 0px 0px;
			}

			.main-menu-btn-icon {
 				left:16px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			}
		}
		#main-menu-state:checked ~ .main-menu-btn { background:$nav-base-bg-color;} //margin: 0 0 1px; height: 75px;}
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:6px; margin-top: -4px; background-color:white; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:white; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }
		ul.sm {
			background: none;
			clear:both;
		}
		@include media(880px) {
			clear:right;
			float:right;
			.menu-block-wrapper > ul.menu > li > a > .sub-arrow { display:none; }
		}
		.menu-block-wrapper > ul.menu > li:last-child { position:relative; }

		.menu-block-wrapper > ul.menu > li {
			border-left:none;
			flex-grow: 1;
			text-align: center;
		}
		.menu-block-wrapper > ul.menu > li > a {

			background:$nav-mob-1st-bg-color;
			text-align: left;
			font:$nav-base-font;
			color:$nav-sub-text-color;
			padding:$nav-sub-base-padding;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				background-color:$nav-hover-bg-color;
				color:$nav-hover-text-color;
			}

			@include media(880px) {
				background-color: transparent; //$nav-base-bg-color;
				font:$nav-base-font;
				color:$nav-base-text-color;
				padding:$nav-base-padding;
				text-transform: uppercase;
				text-align: center;

				@media (min-width:880px) and (max-width:1150px) {
					font: $nav-base-font-smaller;
					padding: $nav-base-padding-smaller;
				}

				&:hover,
				&.highlighted:hover {

					color:$nav-hover-text-color;

					@include media(880px) {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}
				}
			}

			&.current, &.active.current.highlighted {
				//@include media(880px) {
					background-color:$nav-active-bg-color;
					color:$nav-active-text-color;
					border-radius:$nav-active-border-radius;

					@include media(880px) {
						background:$nav-base-bg-color;
					}
				//}
			}
			&.current.highlighted {
				//@include media(880px) {
					background-color:$nav-mob-1st-highlight-bg-color;
					//color:$nav-active-text-color;
					border-radius:$nav-active-border-radius;
				//}
			}

			&.highlighted {
				background-color:$nav-mob-1st-highlight-bg-color;
				//border-top:$nav-mob-expanded-border;

				@include media(880px) {
					background-color:$nav-highlight-bg-color;
					color:$nav-highlight-text-color;
					border-bottom: $nav-highlight-border;
					border-top: none;
					padding:$nav-base-hover-padding;

					@media (min-width:880px) and (max-width:1150px) {
						padding:$nav-base-hover-padding-smaller;
					}
				}

				&:hover {
					color:$nav-highlight-text-color;
				}
			}


			/////////////////////////////
			@media (max-width:879px) {

				&:hover {
					background-color:$nav-sub-hover-bg-color;
					color:$nav-hover-text-color;
					.sub-arrow {
						background:$arrow-white-down no-repeat scroll center center transparent;
						background-size:50%;
					}
				}
				&.current {
					background:$nav-mob-1st-highlight-bg-color;
					color:white; //$nav-sub-text-color;
					border-bottom: $nav-hover-border;
					padding:$nav-sub-base-hover-padding;
					//border-top:$nav-mob-expanded-border;
					.sub-arrow {
						background:$arrow-white-down no-repeat scroll center center transparent;
						background-size:50%;
					}
					&:hover {

						background-color:$nav-sub-hover-bg-color;
						color:$nav-hover-text-color;

						.sub-arrow {
							background:$arrow-white-down no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}

				&.active {
					background-color:$nav-mob-active-bg-color;
					color:$nav-active-text-color;
					.sub-arrow {
						background:$arrow-white-down no-repeat scroll center center transparent;
						background-size:50%;
						border-left-color: white;
						margin-top: -22px;
    				height: 46px;
					}

					&:hover {
						.sub-arrow {
							background:$arrow-white-down no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}

				&.highlighted {
					color: white; //$nav-sub-text-color;
					border-bottom: $nav-hover-border;
					padding:$nav-sub-base-hover-padding;
					.sub-arrow {
						background:$arrow-white-up no-repeat scroll center center transparent;
						background-size:50%;

						height: 43px;
						margin-top: -22px;
					}
					&:hover {
						background-color:$nav-sub-hover-bg-color;
						color:white; //$nav-hover-text-color;
						.sub-arrow {
							background:$arrow-white-up no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}
				&.current.highlighted {
					border-bottom: $nav-highlight-border;
					padding:$nav-sub-base-hover-padding;
				}

				&.active.highlighted {
					.sub-arrow {
						background:$arrow-white-up no-repeat scroll center center transparent;
						background-size:50%;
					}
					&:hover {
						.sub-arrow {
							background:$arrow-white-up no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}
			}
			////////////////////////////////
		}

		@media (max-width:879px) {
			ul.menu.sm > li.first.leaf {
				//border: none;
				//border-top:$nav-mob-expanded-border;
			}
			ul.menu.sm > li.first.first {
				border: none;
			}
			ul.menu.sm {
				position: absolute;
				top: 70px;
				right: 0;
				width: calc(100% - 5%);
				max-width: 400px;
				z-index: 1005;

				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}
			}
		}

		ul.menu.sm-simple {
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);

			@include media(880px) {
				box-shadow:none;
			}
		}

		ul.menu {
			border: none; //$nav-mob-border;
			border-top: none;

			@include media(880px) {
				border:none;
				box-shadow:none;
				&.sm {
					border-top:none;
				}
			}
			li {
				@media (max-width:879px) {
					border-top: $nav-mob-border;
				}

				a {
					cursor:pointer;
					background-color: transparent;
					text-decoration: none;

					.sub-arrow {
						background:$arrow-blue-down no-repeat scroll center center transparent;
						background-size:50%;

						@include media(880px) {
							background:$arrow-blue-right no-repeat scroll center center transparent;
							background-size:contain;
							width: 16px;
						}
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}

				@media (max-width:879px) {

					&.expanded {
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							//border-top:$nav-mob-expanded-borde
							//border-bottom:$nav-mob-expanded-border;

							a.active {
								background-color: $nav-mob-2nd-bg-color;
							}

							li.expanded {
								ul.menu {
									background-color: $nav-mob-3rd-bg-color;
									//border-top:$nav-mob-expanded-border;
									//border-bottom:$nav-mob-expanded-border;

									li:not(.first) {
										//border-top:none;
										border-top:$nav-mob-expanded-border;
									}

									a.active {
										background-color: $nav-mob-3rd-bg-color;
										color: $action-hover;
									}
								}
							}
						}

						ul.menu a.current {
							background-color: $nav-mob-2nd-highlight-bg-color;

							&.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;

								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}
					}

					a {
						.sub-arrow {
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}

					li {
						a {
							.sub-arrow {
								height: 39px;
								margin-top: -19px;
								//border-left: $nav-mob-border;
							}
						}
					}
				}


				ul.menu {

					background:$nav-sub-bg-color;
					@include media(880px) {
						box-shadow:$nav-sub-box-shadow;
					}
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;

					li {
						//border-top:none;
						border-top:$nav-mob-expanded-border;

						@include media(880px) {
							border-top:none;
						}

						ul li.active {
							border-left: 4px solid $action-hover;
							color: $action-hover;
						}

						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;

							@include media(880px) {
								font: $nav-sub-base-font;
							}

							&.current {
								background:$nav-sub-bg-color;
							}
							&:hover, &.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
							.sub-arrow {
								background:$arrow-blue-down no-repeat scroll center center transparent;
								background-size:50%;
								@include media(880px) {
									background:$arrow-blue-right no-repeat scroll center center transparent;
									background-size:contain;
									right:10px;
								}
								text-indent:-999em;
								overflow:hidden;
								right:0;
							}

							&.active {
								.sub-arrow {
									background:$arrow-blue-down no-repeat scroll center center transparent;
									background-size:50%;
									@include media(880px) {
										background:$arrow-blue-right no-repeat scroll center center transparent;
										background-size:contain;
									}
								}
							}
							&:hover, &.active:hover  {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
									@include media(880px) {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:contain;
									}
								}
							}
						}
						&.expanded > a.highlighted {
							background-color:$nav-mob-2nd-highlight-bg-color;
							//border-top:$nav-mob-expanded-border;

							@include media(880px) {
								background-color:$nav-hover-bg-color;
								color:$nav-hover-text-color;
								border-top:none;
							}

							.sub-arrow {
								background:$arrow-blue-up no-repeat scroll center center transparent;
								background-size:50%;
								@include media(880px) {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							&:hover, &.current.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;

								@media (max-width:879px) {
									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
