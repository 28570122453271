/*.i18n-en */
.site-title a {
  background: $logo-en no-repeat scroll 0 0 transparent;
}

#site-header {
  background-color: $header-bg-color;

  &:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }

  //
  // /* Change .header-outer-wrapper to
  // .header-inner-wrapper, main-nav-wrapper
  // to make the menu a band in it's own right. */
  //
  .header-outer-wrapper {
    position: relative;
    @include outer-container($site-max-width);
  }

  @media (max-width: 719px) {
    .header-inner-wrapper {
      display: flex;
      flex-flow: column-reverse;

      .wrapper-region-header {
        text-align: right;
        margin-bottom: $vert-space;
      }

      .site-branding {
        .site-title {
          position: relative;
          top: 3px;
          margin-bottom: 10px;
        }
      }
    }

    .main-nav-wrapper {
      margin-top: -60px;
    }
  }

  .site-branding {
    margin-top: 0;
    margin-left: 1.25rem;
    position: relative;
    @include media(880px) {
      top: 0;
    }

    .site-title {
      position: absolute;
      left: 0;
      top: 2rem;

      a {
        background-size: contain;
        display: block;
        text-indent: -999em;
        overflow: hidden;
        width: 140px;
        height: 84px;
        z-index: 1002;

        @include media($narrow) {
          width: 160px;
          height: 96px;
        }

        @include media(880px) {
          width: 180px;
          height: 120px;
        }
      }
    }

    .site-slogan {
      position: absolute;
      left: 235px;
      top: 2.5rem;
    }
  }

  .block-search {
    display: none;
    position: relative;
    width: 160px;
    align-self: flex-end;
    margin-right: 20px;
    height: auto;
    order: 2;

    @include media(880px) {
      display: inline-block;
    }

    @include clearfix;

    h2 {
      font-family: $base-font-family;
      font-size: 1rem;
      color: $primary;
      float: left;
      line-height: 1.5;
      margin-top: 1rem;
      margin-right: 0.75rem;
      cursor: pointer;
      display: none;

      @include media(880px) {
        margin-right: 2.5rem;
      }
    }

    .form-item-search-block-form {
      margin-top: 0.65rem;
      margin-right: 0.75rem;
      margin-bottom: 0;
      float: left;

      @include media(880px) {
        margin-bottom: 1rem;
        display: inline;
      }

      @include media($wide) {
        margin-bottom: 0;
      }

      input.form-text {
        width: 10rem;
        height: 2.125rem;
        background-color: $header-search-field-bg-color;
        color: $header-search-field-text-color;
        box-shadow: $header-search-field-box-shadow;
        border: $header-search-field-border;
        border-radius: $header-search-field-border-radius;
        font: $header-search-field-font;
        padding: 0.125rem 0.75rem 0.375rem;
      }
    }

    .form-actions {
      float: left;
      margin-top: 0;

      input.form-submit {
        background: transparent $search no-repeat scroll 0 0;
        background-size: 14px;
        width: 0.875rem;
        height: 0.875rem;
        text-indent: -999em;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
      }
    }
  }

  .block-menu {
    display: inline-block;

    @include media(880px) {
      margin-right: 1.5rem;
      margin-bottom: 0.5rem;
      order: 1;
    }

    ul {
      margin-top: 0.875rem;
      margin-bottom: 0;
    }

    li {
      display: inline-block;
      margin: 0 0.75rem 0 0;

      @include media(880px) {
        &.last {
          display: none;
        }
      }
    }

    a {
      font: $header-menu-font;
      color: $header-menu-link-color;

      &:hover {
        color: $header-menu-link-hover;
      }
    }
  }

  .block-locale {
    clear: both;
    display: none;
  }

  .wrapper-region-header {
    float: right;

    @include media($narrow) {
      text-align: right;
    }

    @include media(880px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
    }
  }

  .block-contact {
    clear: both;
    display: none;

    @include media($narrow) {
      display: block;
    }

    @media (min-width: $narrow) and (max-width: 879px) {
      margin-right: 96px;
      margin: 25px 96px 0 0;
    }

    @include media(880px) {
      order: 3;
      width: 100%;
    }

    .section-row .card-body {
      p {
        float: right;
        border: 2px solid $davys_grey;
        color: $davys_grey;
        font-weight: bold;
        line-height: 1.1875;
        padding: 0.875rem 1.75rem;
        text-transform: uppercase;
        margin-right: $vert-space;

        @media (min-width: $narrow) and (max-width: 879px) {
          padding: 0.875rem 1.125rem;
        }
      }

      a {
        background: $cooler_blue;
        text-decoration: none;
        border: solid 2px $cooler_blue;
        color: white;
        margin: calc(-0.875rem - 2px) calc(-1.75rem - 2px);
        padding: 0.875rem calc(1.75rem + 2px);
        display: block;

        &:hover {
          background: white;
          border: solid 2px $cooler_blue;
          color: $cooler_blue;
        }
      }
    }
  }
}

.content-hero {
  .block-contact {
    clear: both;
    display: block;

    @include media($narrow) {
      display: none;
    }

    .section-row {
      padding: 0;
      margin: 0;

      .card-body {

        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        p {
          border: $tertiary-border;
          color: $rich_electric_blue;
          font-weight: bold;
          line-height: 1.1875;
          text-transform: uppercase;
          background-color: white;

          margin: 0;
          font-size: 0.875rem;
          padding: 10px 5px;
          display: inline-block;
          min-width: auto;
          flex-grow: 1;
          text-align: center;
        }

        a {
          background: $action-default;
          color: white;
          margin: -10px;
          display: block;

          padding: 10px;
          box-sizing: content-box;

          &:hover {
            background: $action-hover;
          }
        }
      }
    }
  }
}
