.section-row.section-with-promo {
	padding: 0;
	background-color: transparent !important;
	position: relative;
	min-height: 348px;
	display: table;
	width: 100%;
	
	.section-inner {
    overflow: visible;
   	padding: 0;
    margin: 0;
		display: table-cell;
		vertical-align: middle;
		
		.view-promo {
			@include media($normal) {
				background: $bg_pattern_dark;
			}

			.promo {
				max-width: 100%; //1600px;
    		margin-left: auto;
    		margin-right: auto;
				text-align: center;

				.card-image {
					width: 260px;
					height: 260px;
					border-radius: 50%;
					overflow: hidden;
					padding: 0;
					border: 4px solid white;
					display: inline-block;
					
					@include media($normal) {
						position: absolute;
						width: 348px;
						height: 348px;
						top: 0;
						left: 5%; 
					}
					
					@media (min-width:1600px) {
						left: calc(50% - 720px);
					}
				}

				a.card-promo-text {
					padding: 150px 10% 40px;
					background: $bg_pattern_dark;
					display: block;
   			 	color: white;
					text-align: left;					
					margin-top: -130px;
					
					
					@include media($normal) {
						padding: 40px 5% 40px calc(5% + 378px);
						margin-top: 0;
					}
					
					&:hover {
						/*.promo-title, .promo-description {
							color: $action-default;
						}
						
						.promo-description p {
							background: $long-arrow-blue-right 100% 50% no-repeat;
						}*/
						
						background: $bg_pattern_light;
						transition: background  300ms linear 0s;
					}

					.promo-title {
						font-size: 2.750rem;
						font-weight: 600;
						line-height: 1.2;
    				margin-bottom: 1rem;
					}

					.promo-description p {
						font-size: 1.750em;
						margin: 0;
						font-weight: normal;
						padding-right: 45px;
						display: inline;
						background: $long-arrow-white-right 100% 50% no-repeat;
					}
					
					@media (min-width:1600px) {
						.card-title-field, .card-description {
							max-width: 1050px;
							margin-left: calc(50% - 530px);
						}
					}
				}
			}
		}
	}
}