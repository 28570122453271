.view-attachments {

	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}

	.views-row {
		padding: $vert-space/2 0;
	}

	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;

		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4.375rem;
			background: white;
			.file-extension {
				padding:$vert-space*0.4 $horz-space/4 0;
				text-transform:uppercase;
				font-size:1.125rem;
        line-height:1;
				text-align:center;
				font-weight:800;
				font-family:$title-font-family;
			}
			.file-size {
        padding: 3px 5px 6px;
				font-size:0.750rem;
				text-align:center;
				color:$secondary;
        /*border:$secondary-border;*/
        border-top:none;
			}
		}

		.file-name {
			overflow:hidden;
			color:$davys_grey;
		}
		&:hover {
			.file-name {
				color:$action-default;
			}
		}
	}


	// /* FILE TYPE COLOURS */

  .file-type-docx, .file-type-doc {
		border: 2px solid $doc_colour;

    .file-extension {
      color:$doc_colour;
    }
  }
  .file-type-xlsx, .file-type-xls {
		border: 2px solid $xls_colour;

    .file-extension {
      color:$xls_colour;
    }
  }
  .file-type-pptx, .file-type-ppt {
		border: 2px solid $ppt_colour;

    .file-extension {
      color:$ppt_colour;
    }
  }
  .file-type-pdf {
		border: 2px solid $pdf_colour;

    .file-extension {
      color:$pdf_colour;
    }
  }
  .file-type-rtf, .file-type-txt {
		border: 2px solid $txt_colour;

    .file-extension {
      color:$txt_colour;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
		border: 2px solid $jpg_colour;

    .file-extension {
      color:$jpg_colour;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
		border: 2px solid $zip_colour;

    .file-extension {
      color:$zip_colour;
    }
  }
}


// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}

		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
}
