// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 50px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				padding: 3px;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}
}

.postscript-first .section-row {
	&.palette-default,
	&.palette-alt-1,
	&.palette-alt-2,
	&.palette-alt-3,
	&.palette-highlight {
	 	.slick-nav .slick-dots li  {
			@media(max-width: 719px){
				button {
					border-color: $lapiz_lazuli;
					&:hover {
						background:$lapiz_lazuli;
					}
				}
				&.slick-active button{
					background:$lapiz_lazuli;
				}
			}
		}
	}
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;

	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom: 17px;
		padding: 25px;
	}

	.slick-dots {
		bottom:17px;
	}
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;

		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}

	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:14px;
	}
}

// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;

				@include media($narrow) {
					background-color: transparent;
					margin-bottom: 95px;
				}

				.views-row {
					margin: 0;
					padding: 0;
    			min-height: auto;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}

					.card-slide-content-title {
						background-color: $slide-title-bg-color;

						padding: 20px;
						color: $slide-title-color;
						font: $slide-title-font-small;
						z-index: 150;
						box-shadow: $slide-title-shadow;
						position: relative;



						@include media($narrow) {
							padding: 20px 40px 50px;
							position: absolute;
							right: 80px;
							width: 540px;
							bottom: -10px;
						}

						@include media($normal) {
							right: 160px;
							width: 680px;
							bottom: 30px;
							font: $slide-title-font;
						}

						@include media($wide) {
							right: 200px;
							width: 720px;
						}
					}

					.card-description {
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:20px 20px 70px;
						margin:0;
						color:$slide-text-color;
						height: auto;

						p {
							font:$slide-text-font;
							margin: 0;
						}

						@include media($narrow) {
							position:absolute;
							right: 40px;
							width: 520px;
							top: calc(100% - 20px);
							padding: 40px 30px 20px;
							box-shadow: $slide-text-shadow;
						}

						@include media($normal) {
							right: 120px;
							width: 560px;
							top: calc(100% - 50px);
						}

						@include media($wide) {
							right: 160px;
							width: 600px;
						}

						.card-slide-description {
							padding: 0;
							min-height: auto;
						}
					}
				}
			}
		}

		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}

	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		width:100%;
		left:0;

		@include media($narrow) {
			left: auto;
			right: 50px;
			bottom: 30px;
			width: 620px;

			.slick-dots {
				bottom: -30px;
			}
		}

		@include media($normal) {
			left: auto;
			right: 130px;
			bottom: 70px;
			width: 760px;
		}

		@include media($wide) {
			right: 170px;
			width: 800px;
		}


	}
}

.side-row.section-with-slider {

	.view-slider {
		.view-content .slick-list .slick-track {
			margin-bottom: 0;

			.views-row {
				.card-image {
					background-color: $palette-default-bg-color;
				}
				.card-slide-content-title {
					position: relative;
					width: 100%;
					right: 0;
					bottom: 0;
					padding: 20px 30px;
					font: $slide-title-font-small;
				}
				.card-description {
					position: relative;
					width: 100%;
					box-shadow: none;
					right: 0;
					padding: 20px 30px;
				}
			}
		}

		.view-footer {
			right: 0;

			.slick-dots {
				bottom: 14px;
			}
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;

			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}

	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
		margin-top: 20px;
		height: 53px;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}

	}
	.view-footer {
		text-align:center;
	}
}



// SECTION WITH GALLERY
aside .side-row.section-with-gallery {
	padding: 20px 20px 0;
	.card-photos {
		.view-footer {
			button.slick-arrow {
				&.slick-prev {
					left: -20px;
				}
				&.slick-next {
					right: -20px;
				}
			}
		}
	}
}
