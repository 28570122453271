/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	.views-row {
		margin-bottom:15px;
		margin-left: 10px;
	}

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 40px;
		box-sizing: border-box;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.card-qa-question {
		p {margin-bottom: 0;}
		text-decoration: none;
		font: 600 1.222em/30px $title-font-family;
		color: $lapiz_lazuli;
		padding: 10px 0 10px 40px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand-lazuli no-repeat scroll 0 50% / 20px 20px transparent;
		&:hover {
			color:$prussian_blue;
			background: $ico-expand-prussian no-repeat scroll 0 50% / 20px 20px transparent;
		}

		&.active {
			background: $ico-collapse-lazuli no-repeat scroll 0 50% / 20px 20px transparent;
			&:hover {
				color:$prussian_blue;
				background: $ico-collapse-prussian no-repeat scroll 0 50% / 20px 20px transparent;
			}
		}
	}
}


.section-row {
	&.palette-alt-1,
	&.palette-alt-2,
	&.palette-alt-3 {
		.view-faqs {
			.card-qa-question {
				color: $lapiz_lazuli;
				background: $ico-expand-lazuli no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$vivid_blue;
					background: $ico-expand-sky no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $ico-collapse-lazuli no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						color:$vivid_blue;
						background: $ico-collapse-sky no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
			}
		}
	}
	&.palette-highlight {
		.view-faqs {
			.card-qa-question {
				color: $white;
				background: $ico-expand-white no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$white;
					background: $ico-expand-sky no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $ico-collapse-white no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						background: $ico-collapse-sky no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
				.card-qa-answer {
					color: white;
				}
			}
		}
	}
}
