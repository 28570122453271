@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i|Raleway:400,400i,600,600i,800');

$base-font-family      		: 'Lato', sans-serif;
$title-font-family    		: 'Raleway', sans-serif;
$subtitle-font-family 	 	: 'Raleway', sans-serif;


$base-font-size						: 16px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$header-menu-font 				: 500 /*0.875rem*/ 1/1 $base-font-family;
$header-search-field-font : /*0.875rem*/ 1/1.5 $base-font-family;

$nav-base-font        	 	: 600 1.125rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 600 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1.125rem/1.3 $base-font-family;

$breadcrumb-font 					: bold 0.875rem/1 $base-font-family;

$section-row-title-font 					: 600 2.250rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 600 2rem/1.25 $title-font-family; // /* Nugget title */

$side-row-title-font 			: 600 1.25rem/1.3 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family		: $title-font-family;
$card-title-font        	: 600 1.125rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: 600 1.125rem/1 $title-font-family;
$card-feed-link-font			: 600 0.8125rem/1.46153846153846 $title-font-family;

$button-link-font 				: 600 $base-font-size/1 $title-font-family;
$card-feed-link-font			: 600 $base-font-size/1 $title-font-family;

/* FORMS */
$button-font 							: 600 $base-font-size/1 $title-font-family;

