#site-footer {
	.outer-wrapper {
		padding:0 $horz-space/2;
	}

	h2 {
		color:$footer-first-text;
	}

	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}

	.card-follow-us-on ul li { display: inline-block; }

	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		font-size: 1.125rem;

		@include palette-footer-first-slick-controls;

		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}

		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}


		a {
			color:$footer-first-action;
				text-decoration: none;
			&:hover {
				color:$footer-first-action-hover;
			}
		}

		.card-body p {
			margin-bottom: 0.313rem;
		}
	}
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;

		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}

		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;

			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
		}

	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		/*font-size:0.875em;*/

		a {
			color:$footer-third-text;
			text-decoration: none;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		#copyright {
			float: none;
			@include media(880px) {
				float: left;
			}
		}
		#manta-ray-media {
			float: none;
			@include media(880px) {
				float: right;
			}
			a {
				color: $lapiz_lazuli;
				text-decoration: underline;
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				li {
					display: inline-block;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid $secondary-border-color;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin-right: 10px;
		text-indent: -9999px;

		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			border: $card-links-border;

			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background-color: $card-links-hover-bg;
			}
		}

		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter-sky no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-newsletter-lazuli no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-sky no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-linkedin-lazuli no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"],
		a[href^="http://twitter.com"] {
			background: $ico-twitter-sky no-repeat scroll 50% 50% / 20px 20px transparent;
			&:hover {
				background: $ico-twitter-lazuli no-repeat scroll 50% 50% / 20px 20px $card-links-hover-bg;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-sky no-repeat scroll 50% 50% / 18px 18px transparent;
			&:hover {
				background: $ico-facebook-lazuli no-repeat scroll 50% 50% / 18px 18px $card-links-hover-bg;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-sky no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-gplus-lazuli no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}
	}


	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
}
