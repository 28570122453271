html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$primary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;

	&.card-title {
		font-family:$title-font-family;
	}
	&.card-title-sm {
		font-family:$title-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;

		a {
			color:$action-default;

			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:600;
}
h3, h4, h5, h6 {
	font-weight:600;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$title-font-family;
	}
}
h1 {
	font-size:2rem;
	line-height:1.1875;
	margin-bottom:$vert-space;

	@include media($normal) {
		font-size:2.25rem;
		line-height: 1.33333333333333;
	}
}
h2 {
	font-size:1.75rem;
	line-height:1.32;
	margin-bottom:0.5em;
	font-weight: 600;

	@include media($normal) {
		font-size:2rem;
		line-height: 1.28571428571429;
	}
}
h3 {
	font-size:1.5rem;
	line-height:1.375; /*1.273;*/
	margin-bottom:0.5rem;

	@include media($normal) {
		font-size:1.75rem;
		line-height: 1.33333333333333;
	}
}
h3.block__title {

	font-size:1.375em;

	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.750rem;
	}
}
h4 {
	font-size:1.375rem;
	line-height:1.375; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.5rem;
		line-height: 1.36363636363636;
	}
}
h5 {
	font-size:1.125rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.25rem;
		line-height: 1.42105263157895;
	}
}
h6 {
	font-size:1.063rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.125rem;
		line-height: 1.42105263157895;
	}
}
p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;

			&.smaller-text {
				font-size: 0.750rem;
				line-height: 1.5;

				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.250rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}
			@include media($normal) {
				font-size:1.125rem;
				line-height: 1.44444444444444;

				&.smaller-text {
					font-size: 0.750rem;
					line-height: 1.5;
				}
				&.larger-text {
					font-size: 1.250rem;
					line-height: 1.3;
				}
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.75em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.875rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:700;
}

.card-body a:link {
    text-decoration: underline;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration: none;

	&.active {
		color:$action-active;
		text-decoration: none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}

	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:1rem;
	font-style:normal;
	text-transform: uppercase;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}
sup { vertical-align: super; }
sub { vertical-align:sub; }


.view-attachments {
	.attachment-link {
		.file-name {
			font-size: 1.375rem;
			font-weight: 600;
		}
	}
}
